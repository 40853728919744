import React, { useRef, useState, useMemo } from 'react'

export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useRef();

  React.useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) =>
    setIsIntersecting(entry.isIntersecting),
  );
  })

  // React.useEffect(() => {
  //   observer.current.observe(ref.current);
  //   return () => {
  //     observer.current.disconnect();
  //   };
  // }, [ref, observer.current]);

  return isIntersecting;
}
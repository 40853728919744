import React, { useRef, useState, useMemo } from 'react'
import { useIsInViewport } from './IsInViewport';
import { StaticQuery, graphql, Link } from "gatsby"

const ABanner = (props) => {
  const [visible, setVisible] = useState(false);
  const ref1 = useRef(null)
  const isInViewport1 = useIsInViewport(ref1);
  const data = props.data;
  const texts = props.data.allTexts.edges[0].node;
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }
  React.useEffect(() => {
    if(isInViewport1){
      setTimeout(()=>{
        setVisible(true)
      },[300])
    }
    // else{
    //   setVisible(false)
    // }
  }, [isInViewport1]);
  return (
    <div ref={ref1} className={visible ? (props.offset ? "ab offset" : "ab abVisible") : "ab abNonVisible"}>
      <div className="mobile-background">
      <p className="subTitle">{t(data.allDataJson.edges[0].node.adBanner.subTitle, texts)}</p>
      <h2 className=''>{t(data.allDataJson.edges[0].node.adBanner.title, texts)}</h2>
        {/* <p className="desrc">Whether you’re an individual looking to send a single letter or a <br /> business managing bulk mailings, UploadLetters.com is here to <br /> help. Contact us today or sign up to start using our services and <br /> experience how easy and efficient mailing can be!</p> */}
        <div className="btnHolder">
          <a href='https://app.uploadletters.com/login' className="button primary">{t(data.allDataJson.edges[0].node.adBanner.login, texts)} <div className="whiteDot"/></a>
          <Link to="/contact-us/" className='button white bdContactUs'>{t(data.allDataJson.edges[0].node.adBanner.contact, texts)}</Link>
        </div>
      </div>
    </div>
  )
}


const ABannerQuery = (props) => (
  <StaticQuery
    query={graphql`
      query abQuery {
        allDataJson {
          edges {
            node {
              adBanner {
                contact
                login
                subTitle
                title
              }
            }
          }
        }
        allTexts {
          edges {
            node {
              adBanner_contact
              adBanner_login
              adBanner_subTitle
              adBanner_title
            }
          }
        }
      }
    `}
    render={(data) => <ABanner data={data} {...props}/>}
  />
)

export default ABannerQuery

import React, { useState } from "react"
import { MainLayout } from "../layouts/main.layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import hero from "../assets/img/checkMailHero.png"
import option1 from "../assets/img/checkMailLetters.png"
import option2 from "../assets/img/uspsTrackingIntegration.png"
import option3 from "../assets/img/dynamicTrackingPortal.png"
import option4 from "../assets/img/flexibleMailingOptions.png"
import ABanner from "../components/features/ab.component"

import envelope from "/src/assets/img/envelopSliderIcon.svg"
import letter from "/src/assets/img/letterSliderIcon.svg"
import monitor from "/src/assets/img/monitorSliderIcon.svg"
import reward from "/src/assets/img/rewardSliderIcon.svg"

import dynamic from "/src/assets/img/dynamic.svg"
import competetive from "/src/assets/img/technology.svg"
import service from "/src/assets/img/next-day-service.svg"
import secure from "/src/assets/img/secure.svg"
import legal from "/src/assets/img/legal.svg"
import business from "/src/assets/img/business.svg"
import government from "/src/assets/img/goverment.svg"
import individuals from "/src/assets/img/individuals.svg"

const CertifiedMail = ({ location }) => {
  const [expandedBlock, setExpandedBlock] = useState(1)
  const [activeImage, setActiveImage] = useState(option1)
  const [isFading, setIsFading] = useState(false)

  const blocks = [
    {
      id: 1,
      title: "In addition to pressure-sealed checks, we offer flexible mailing options to suit your needs",
      description: `<div style="display: flex; gap: 10px;">
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Standalone Checks </span> <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw;">Send a check by itself for a clean and professional presentation.</span></div>
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Checks with Letters</span>    <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw">Include an accompanying letter for added communication, whether it's an invoice, explanation, or another document.</span></div>
      </div>`,
      imgSrc: option1,
      icon: envelope,
    },
    {
      id: 2,
      title: "You can select from a variety of our mailing services, including",
      description: `<div style="display: flex; gap: 10px;">
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">First-Class Mail </span> <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw;">A reliable and cost-effective option for sending checks.</span></div>
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Certified Mail</span>    <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw">Offers additional tracking and proof of delivery for more sensitive or important check mailings.</span></div>
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Priority Mail</span>    <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw">For faster delivery with comprehensive tracking</span></div>
      </div>
      <div style="padding: 5px 5px; display: inline-block;">No matter your volume, our system supports sending one check at a time or thousands in bulk—giving you the flexibility to meet your payment needs with ease.</div>`,
      imgSrc: option1,
      icon: envelope,
    },
    {
      id: 3,
      title: "Secure Integration with Stripe",
      description: `<div style="padding: 5px 5px; display: inline-block;">Through our integration with Stripe, clients can securely enter their individual or business checking account information to begin sending checks. The setup process is quick and secure:
Account Verification: We verify ownership of your bank account through a microdeposit process, which typically takes 1-2 business days.
Start Sending Checks: Once verified, you can start sending checks for us to print and mail immediately—no additional setup required.
Our 100% self-service solution gives you full control over your check printing setup and enables you to easily submit check requests for us to print and mail on your behalf.</div>`,
      imgSrc: option2,
      icon: reward,
    },
    {
      id: 4,
      title: "Track and Manage Your Checks",
      description: `<div style="padding: 5px 5px; display: inline-block;">Track all your checks throughout the USPS delivery cycle with our robust tracking system. You'll have full visibility over each check, including key USPS scan events, ensuring that your checks are properly mailed. Additionally, you can access and view check images for future reference, providing an added layer of accountability and record-keeping.</div>`,
      imgSrc: option2,
      icon: reward,
    },
  ]

  const toggleBlock = blockId => {
    if (expandedBlock !== blockId) {
      setIsFading(true)
      setTimeout(() => {
        setExpandedBlock(blockId)
        setActiveImage(blocks.find(block => block.id === blockId).imgSrc)
        setIsFading(false)
      }, 500) // This should match the CSS transition duration
    }
  }

  return (
    <>
      <MainLayout location={location} isAbsolute={true}>
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
          </script>
        </Helmet>
        <div className="introSlideCertifiedWrapper">
          <div className="introSlideCertified">
            <div className="slideContent">
              <div className="whatIsCont gutter120">
                  <div className="certifiedsubTitle">
                    Check Printing and Mailing Services
                  </div>
                  <h1>
                    Affordable, Reliable, and  <br /> Fast Shipping Solutions for <br />{" "}
                    Printed Documents
                  </h1>
                  <p className="certifiedTitleDesription">
                  At UploadLetters.com, we offer a professional, reliable and cost-effective solution for check printing and mailing through our platform. Whether you need to send a handful of checks to vendors or manage larger applications for payroll or class action payouts, our services make it easy to securely and efficiently send checks with just a few clicks.
                  </p>
                  <div className="btnHolder getStartedWrapper">
                    <a
                      href="https://app.uploadletters.com/register"
                      className="button intoSlideButton"
                    >
                      <div className="intoSlideButtonText">Get Started</div>
                    </a>
                  </div>
                  <img src={hero} alt="hero"  className="certifiedHero"/>
              </div>
            </div>
          </div>
        </div>

        <div className="whatWeDoSlide" id="what-we-do">
          <div className="slideContent whatWeDoContBG">
            <div className=" whatIsContMain ">
              <div className="whatWeDoTitleBlock">
                <div>
                  <h1 className="whatWeDocertifiedsubTitle">
                  Pressure Seal Checks for a Seamless Solution
                  </h1>
                </div>
                <div className="whatWeDoDescription">
                Our pressure seal check printing option provides a self-contained check solution that ensures your checks are professionally printed and securely sealed. This option is perfect for organizations looking for a streamlined and cost-effective way to send checks without the need for envelopes or additional materials. The pressure-sealed forms create a tamper-evident package that guarantees your check arrives intact and confidential.
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="gutter60">
          <div className="mainSliderContainer paddingRight">
            <div className="splitContainer">
              <div className="optionsContainer">
                <h1 className="typesTitle textLeft">
                Multiple Mailing Options
                </h1>
                {blocks.map(block => (
                  <div
                    key={block.id}
                    className={`optionBlock ${
                      expandedBlock === block.id ? "expanded" : ""
                    }`}
                    onClick={() => toggleBlock(block.id)}
                  >
                    <div className="optionIconBlock">
                      <img src={block.icon} alt="icon" className="optionIcon" />
                    </div>
                    <div>
                      <div className="optionTitleBlock">
                        <div className="optionTitle">{block.title}</div>
                      </div>
                      {expandedBlock === block.id && (
                        <p
                          className="optionContent"
                          dangerouslySetInnerHTML={{
                            __html: block.description,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={`imageContainer ${
                  isFading ? "fade-out" : "fade-in"
                }`}
              >
                <img src={activeImage} alt="Selected Option" />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mainContainer whatIsCont"
          style={{ background: "#f2f3fc" }}
        >
          <h2 className="whatIsTitle">Why Choose UploadLetters.com?</h2>
          <div className="whatIsSubTitle">Whether you're paying vendors, managing payroll, or disbursing payments for larger applications, our check printing and mailing services provide a reliable, professional, and cost-effective solution.</div>
          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={dynamic} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Efficient Check Printing:</span> <br />
                Choose between our secure, self-contained pressure-sealed checks or traditional checks in envelopes, offering professional solution for all your check mailing needs.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={competetive} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Flexible Mailing Options:</span>{" "}
                <br />
                Choose from standalone checks or checks with letters, sent via First-Class, Certified, or Priority Mail.
              </div>
            </div>
          </div>

          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={service} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Bulk or Single Check Sending:</span> <br />
                Whether you’re sending one check or thousands, our platform accommodates your needs.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={secure} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">
                Secure Stripe Integration:
                </span>{" "}
                <br />
                Enter and verify your checking account information safely, with no additional setup required.
              </div>
            </div>
            <div className="advantagesItem">
              <img src={dynamic} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Real-Time Tracking:</span> <br />
                Track your checks through USPS and access check images for peace of mind and future reference.
              </div>
            </div>
          </div>
        </div>
        <div className="mainContainer gutter60">
          <ABanner />
        </div>
      </MainLayout>
    </>
  )
}

CertifiedMail.defaultProps = {
  location: {},
}

export default CertifiedMail

export const Head = () => (
  <Seo
    title="UploadLetters.com - Certified Mail"
    description="Trust UploadLetters.com for online mailing. Explore Certified, First-Class, and Priority Mail solutions, redefining the mailing experience."
  />
)
